.activity-list li a span.badge {
  color: #fff;
}

.datepicker-days table > thead > tr {
  background-color: #f2f2f2;
}

.datepicker-days table > tbody > tr > td.day {
  cursor: pointer;
}

.datepicker-days table > tbody > tr > td.today {
  background-color: #fcf8e3;
  color: #c09853;
}

.datepicker-days table > tbody > tr > td.active {
  background-color: #a9dba9;
  border-color: #46a546;
}

.datepicker-days table > tbody > tr > td.disabled {
  background-color: #f2f2f2;
  color: #7d7a7a;
  cursor: default;
}

.progress {
  position: fixed;
  height: 15px;
  display: block;
  width: 100%;
  background-color: #dfeedf;
  border-radius: 2px;
  background-clip: padding-box;
  margin: 0;
  overflow: hidden;
  z-index: 10;
}

.progress .determinate {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #26a69a;
  transition: width .3s linear;
}

.progress .indeterminate {
  background-color: #468847;
}

.progress .indeterminate:before {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
  animation: indeterminate 2.1s cubic-bezier(0.65, 0.815, 0.735, 0.395) infinite;
}

.progress .indeterminate:after {
  content: '';
  position: absolute;
  background-color: inherit;
  top: 0;
  left: 0;
  bottom: 0;
  will-change: left, right;
  -webkit-animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  animation: indeterminate-short 2.1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
  -webkit-animation-delay: 1.15s;
  animation-delay: 1.15s;
}

@-webkit-keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@keyframes indeterminate {
  0% {
    left: -35%;
    right: 100%;
  }
  60% {
    left: 100%;
    right: -90%;
  }
  100% {
    left: 100%;
    right: -90%;
  }
}

@-webkit-keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}

@keyframes indeterminate-short {
  0% {
    left: -200%;
    right: 100%;
  }
  60% {
    left: 107%;
    right: -8%;
  }
  100% {
    left: 107%;
    right: -8%;
  }
}
